<template>
  <div>
    <template v-if="isIframe != -1">
      <div
        class="tile nd-imgapps apps_tile handle"
        :style="
          'background-image: linear-gradient(14deg, rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.47) 19%, hsla(0, 0%, 100%, 0)), url(\'' +
          item.image +
          '\');  background-position: 0px 0px, 50% 50%;background-size: auto, cover;'
        "
        @click="getLink()"
      >
        <div class="nd-div-leftmarge inside-txt" :style="getStyle()">
          <div class="white nd-design">{{ item.title }}</div>
        </div>
        <div v-if="config" class="delete-tile-div large-img">
          <div @click="edit()" class="change">
            <img src="images/edit-button-white.png" loading="lazy" class="edit-img" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="tile nd-imgapps apps_tile handle noimage">
        <div v-if="config" class="delete-tile-div large-img">
          <div @click="edit()" class="change">
            <img src="images/edit-button-white.png" loading="lazy" class="edit-img" />
          </div>
        </div>
        <Loading />
      </div>
    </template>
  </div>
</template>
<script>
import { not_local } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js"

import methods from "@/general/HelperMethods/General.js"

import Loading from "@/general/Elements/2.0/OtherElements/Widgets/LoadingWidget.vue"

import WidgetCollection from "@/general/Objects/WidgetCollection"
import WidgetRepository from "@/general/Objects/Widget"

export default {
  props: ["item", "type"],
  components: {
    Loading,
  },
  data: () => {
    return {
      isIframe: -1,
      account: [],
    }
  },
  async created() {
    const accs = new WidgetCollection()
    this.account = await accs.getAccount(this.item.account_uid)
    this.isIframe = await this.getIframeCompatibility()
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    config: function () {
      return this.store.environment.config
    },
    changeWidget: {
      get() {
        return this.store.environment.setWidget
      },
      set(value) {
        this.store.environment.setWidget = value
      },
    },

    link: function () {
      return this.config ? false : this.item.data[0].field
    },
    localWidget: {
      get() {
        return this.store.environment.localWidget
      },
      set(value) {
        this.store.environment.localWidget = value
      },
    },
    colors: function () {
      return this.store.client_data.color
    },
  },
  methods: {
    async edit() {
      const widgetObject = new WidgetRepository()
      const widget_legend = await widgetObject.getWidgetByName(this.item.name)
      this.changeWidget = {
        item: {
          title: this.item.title,
          type: this.type,
          category: widget_legend.category_id,
          name: widget_legend.name,
          widget_uid: this.item.uid,
          action: "update",
          account_uid: this.item.account_uid,
        },
      }

      this.$router.push({
        name: "SetWidget",
      })
    },

    async getStatus(url) {
      var response = await this.axios.post(
        "https://api.themozo.app/general/v2/isIframeCompatible.php",
        {
          url: url,
        }
      )
      return response.data
    },

    async getLink() {
      if (this.isIframe == -1) {
        return
      }
      const wCol = new WidgetCollection()
      this.account = await wCol.getAccount(this.item.account_uid)

      const wObject = new WidgetRepository()
      const url = await wObject.getField(
        this.account.widget,
        this.account.data[0].field,
        this.account.data[0].name
      )

      if (this.config) {
        return
      } else if (this.item.name === "mail" || this.item.name === "phone") {
        window.open(url, "_self")
      } else if (not_local.includes(this.item.name)) {
        window.open(url, "_blank")
      } else if (this.isIframe == 1) {
        this.localWidget = {
          view: "inapp",
          item: this.item,
        }
      } else if (this.isIframe == 2) {
        window.open(url, "_self")
      } else {
        window.open(url, "_blank")
      }

      this.setTag()
    },

    async getIframeCompatibility() {
      const wCol = new WidgetCollection()
      const account = await wCol.getAccount(this.item.account_uid)

      const wObject = new WidgetRepository()
      const url = await wObject.getField(
        account.widget,
        account.data[0].field,
        account.data[0].name
      )

      if (this.config) {
        return 0
      } else if (this.item.name === "mail" || this.item.name === "phone") {
        return 0
      } else if (not_local.includes(this.item.name)) {
        return 0
      }
      return await this.getStatus(url)
    },

    setTag() {
      var uid = 0
      if (this.user) {
        uid = this.user.uid
      }
      methods.setTag(
        this.$gtag,
        this.account.data[0].field,
        this.getPageUrl(),
        this.item.title,
        this.item.name,
        uid,
        this.type,
        this.store.environment.data.table,
        this.store.environment.data.group,
        this.store.environment.data.branch,
        this.store.client_data.url
      )
    },
    getPageUrl() {
      return window.location.href
    },

    getStyle() {
      return (
        "background-image: repeating-radial-gradient(" +
        "circle at 100% 30%," +
        this.colors[1] +
        "," +
        this.colors[1] +
        ");"
      )
    },
  },
}
</script>

<style scoped>
.tile.nd-imgapps {
  text-decoration: none;
  cursor: pointer;
}

.white.nd-design {
  height: 100%;
  line-height: 18px;
  white-space: initial;
  text-align: left;
  font-weight: 600;
  margin: 0px;
}

.nd-div-leftmarge.inside-txt {
  max-width: 90%;
  padding: 4% 5%;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .tile.nd-imgapps {
    height: 200px;
  }
}

.noimage {
  height: 200px;
  width: 100%;
  background-image: none;
  background-color: white;
}
</style>
