<template>
  <div v-if="isLoaded" class="banner-div">
    <template v-if="getData('link') != null && getData('link') != ''">
      <a @click="getLink()" class="banner-linkblock nd-link-block" id="linkBlock">
        <img loading="eager" :src="item.image" class="banner_image" />
        <div class="text-banner-div">
          <h4 class="bheading">{{ item.title }}</h4>
          <p class="bparagraph">{{ getData("body") }}</p>
        </div>
      </a>
    </template>
    <template v-else>
      <div class="banner-linkblock">
        <img loading="eager" :src="item.image" class="banner_image" />
        <div class="text-banner-div">
          <h4 class="bheading">{{ item.title }}</h4>
          <p class="bparagraph">{{ getData("body") }}</p>
        </div>
      </div>
    </template>
    <div v-if="config" class="icon-position">
      <div @click="edit()" class="change">
        <img
          src="images/edit-button-white.png"
          loading="lazy"
          alt="mozo hospitality"
          class="edit-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { not_local } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js"

import methods from "@/general/HelperMethods/General.js"

import WidgetCollection from "@/general/Objects/WidgetCollection"
import WidgetRepository from "@/general/Objects/Widget"

export default {
  props: ["item", "index", "type"],
  data: () => {
    return {
      isIframe: -1,
      account: [],
      isLoaded: false,
    }
  },
  async created() {
    const accs = new WidgetCollection()
    this.account = await accs.getAccount(this.item.account_uid)
    this.isIframe = await this.getIframeCompatibility()
    this.isLoaded = true
  },

  methods: {
    async edit() {
      const widgetObject = new WidgetRepository()
      const widget_legend = await widgetObject.getWidgetByName(this.item.name)
      this.changeWidget = {
        item: {
          type: this.type,
          category: widget_legend.category_id,
          name: widget_legend.name,
          widget_uid: this.item.uid,
          action: "update",
          account_uid: this.item.account_uid,
        },
      }

      this.$router.push({
        name: "SetWidget",
      })
    },

    getData(data) {
      var x = this.account.data.filter(function (el) {
        return el.name === data
      })

      return x[0].field
    },

    async getStatus(url) {
   

      var response = await this.axios.post(
        "https://api.themozo.app/general/v2/isIframeCompatible.php",
        {
          url: url,
        }
      )
      return response.data
    },
    async getLink() {
      if (this.isIframe == -1) {
        return
      }
      const wCol = new WidgetCollection()
      this.account = await wCol.getAccount(this.item.account_uid)


      if (this.account.data[0].field == null) {
        return
      }
      const wObject = new WidgetRepository()
      const url = await wObject.getField(
        this.account.widget,
        this.account.data[0].field,
        this.account.data[0].name
      )
      
      
      if (this.config) {
        
        return
      } else if (this.item.name === "mail" || this.item.name === "phone") {
        window.open(url, "_self")
      } else if (not_local.includes(this.item.name)) {
        window.open(url, "_blank")
      } else if (this.isIframe == 1) {
 
        this.localWidget = {
          view: "inapp",
          item: this.item,
        }
      } else if (this.isIframe == 2) {

        window.open(url, "_self")
      } else {

        window.open(url, "_blank")
      }

      this.setTag()
    },

    

    async getIframeCompatibility() {

      const wCol = new WidgetCollection()
      const account = await wCol.getAccount(this.item.account_uid)

      const wObject = new WidgetRepository()
      const url = await wObject.getField(
        account.widget,
        account.data[0].field,
        account.data[0].name
      )

        if (/mailto:/i.test(url)) {
          return 1
        } else if (/^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:[a-zA-Z0-9._-](?:(?:[a-zA-Z0-9._-]){0,28}(?:[a-zA-Z0-9._-]))?\/?)+$/i.test(url)) {
          return 0
        }  


      if (this.config) {
        return 0
      } else if (this.item.name === "mail" || this.item.name === "phone") {
        return 0
      } else if (not_local.includes(this.item.name)) {
        return 0
      } else if (await this.getStatus(url)) {
        return 1
      } else {
        return 0
      }

      

    },
    async setTag() {
      var uid = 0
      if (this.user) {
        uid = this.user.uid
      }

      methods.setTag(
        this.$gtag,
        this.account.data[0].field,
        this.getPageUrl(),
        this.item.title,
        this.item.name,
        uid,
        this.type,
        this.store.environment.data.table,
        this.store.environment.data.group,
        this.store.environment.data.branch,
        this.store.client_data.url
      )
    },
    getPageUrl() {
      return window.location.href
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    config: function () {
      return this.store.environment.config
    },
    changeWidget: {
      get() {
        return this.store.environment.setWidget
      },
      set(value) {
        this.store.environment.setWidget = value
      },
    },
    localWidget: {
      get() {
        return this.store.environment.localWidget
      },
      set(value) {
        this.store.environment.localWidget = value
      },
    },
  },
}
</script>

<style>
.change {
  cursor: pointer;
}

.banner-linkblock {
  justify-content: space-between;
}

.text-banner-div {
  width: 100%;
}

.nd-link-block {
  cursor: pointer;
}
</style>
