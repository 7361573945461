<template>
  <div @click="getLink()" class="tile_newsocial nd-apps handle">
    <div class="tile nd-small apps_tile" :class="isShortWidgets">
      <template v-if="config">
        <div @click="edit()" class="delete-tile-div">
          <div class="change">
            <img src="/images/edit-button-white.png" loading="lazy" class="edit-img" />
          </div>
        </div>
      </template>
      <template v-if="isIframe != -1">
        <Icon :widget="icon" :colors_arr="colors[2].icons" />
      </template>
      <template v-else>
        <Loading />
      </template>
    </div>
    <div v-if="text" class="nd-div-leftmarge">
      <div class="white nd-design">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import Icon from "@/general/Elements/2.0/OtherElements/Widgets/WidgetIcon.vue"

import { not_local } from "@/general/Elements/2.0/OtherElements/Widgets/widget_media.js"

import methods from "@/general/HelperMethods/General.js"

import Loading from "@/general/Elements/2.0/OtherElements/Widgets/LoadingWidget.vue"
import Widget from "@/general/Objects/Widget"
import WidgetCollection from "@/general/Objects/WidgetCollection"
import WidgetRepository from "@/general/Objects/Widget"

export default {
  props: ["item", "index", "text", "type"],
  data: () => {
    return {
      isIframe: -1,
      account: [],
      icon: "",
    }
  },
  components: {
    Icon,
    Loading,
  },
  async created() {
    const widgetObject = new Widget()
    this.icon = (await widgetObject.getWidgetByName(this.item.name)).view_icon

    this.isIframe = await this.getIframeCompatibility()
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    isShortWidgets: function () {
      return this.type == "small_tile_collection" ? "smaller-icon" : ""
    },

    changeWidget: {
      get() {
        return this.store.environment.setWidget
      },
      set(value) {
        this.store.environment.setWidget = value
      },
    },
    config: function () {
      return this.store.environment.config
    },
    colors: function () {
      return this.store.client_data.color
    },

    localWidget: {
      get() {
        return this.store.environment.localWidget
      },
      set(value) {
        this.store.environment.localWidget = value
      },
    },

    user: function () {
      return this.store.user
    },
  },

  methods: {
    async edit() {
      const widgetObject = new Widget()
      const widget_legend = await widgetObject.getWidgetByName(this.item.name)

      this.changeWidget = {
        item: {
          title: this.item.title,
          type: this.type,
          category: widget_legend.category_id,
          name: widget_legend.name,
          widget_uid: this.item.uid,
          action: "update",
          account_uid: this.item.account_uid,
        },
      }

      this.$router.push({
        name: "SetWidget",
      })
    },

    async getStatus(url) {
      var response = await this.axios.post(
        "https://api.themozo.app/general/v2/isIframeCompatible.php",
        {
          url: url,
        }
      )

      return response.data
    },

    async getLink() {
      if (this.isIframe == -1) {
        return
      }
      const wCol = new WidgetCollection()
      this.account = await wCol.getAccount(this.item.account_uid)

      const wObject = new WidgetRepository()
      const url = await wObject.getField(
        this.account.widget,
        this.account.data[0].field,
        this.account.data[0].name
      )

      if (this.config) {
        return
      } else if (this.item.name === "mail" || this.item.name === "phone") {
        window.open(url, "_self")
      } else if (not_local.includes(this.item.name)) {
        window.open(url, "_blank")
      } else if (this.isIframe == 1) {
        this.localWidget = {
          view: "inapp",
          item: this.item,
        }
      } else if (this.isIframe == 2) {
        window.open(url, "_self")
      } else {
        window.open(url, "_blank")
      }

      this.setTag()
    },

    async getIframeCompatibility() {
      const wCol = new WidgetCollection()
      const account = await wCol.getAccount(this.item.account_uid)

      const wObject = new WidgetRepository()
      const url = await wObject.getField(
        account.widget,
        account.data[0].field,
        account.data[0].name
      )

      if (this.config) {
        return 0
      } else if (this.item.name === "mail" || this.item.name === "phone") {
        return 0
      } else if (not_local.includes(this.item.name)) {
        return 0
      }
      return await this.getStatus(url)
    },

    async setTag() {
      var uid = 0
      if (this.user) {
        uid = this.user.uid
      }

      const wCol = new WidgetCollection()
      const account = await wCol.getAccount(this.item.account_uid)

      methods.setTag(
        this.$gtag,
        account.data[0].field,
        this.getPageUrl(),
        this.item.title,
        this.item.name,
        uid,
        this.type,
        this.store.environment.data.table,
        this.store.environment.data.group,
        this.store.environment.data.branch,
        this.store.client_data.url
      )
    },

    getPageUrl() {
      return window.location.href
    },
  },
  watch: {
    item: {
      async handler() {
        const widgetObject = new Widget()
        this.icon = (await widgetObject.getWidgetByName(this.item.name)).view_icon
      },
    },
  },
}
</script>

<style scoped>
.tile.nd-imgapps {
  box-shadow: 0px 0px 0px 0px;
}

.tile.nd-small.smaller-icon {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
}

.tile_newsocial.nd-apps {
  cursor: pointer;
}

@media only screen and (max-width: 479px) {
  .tile.nd-small.smaller-icon {
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
  }
}

.tile.nd-small.apps_tile {
  background-color: transparent;
}
</style>
