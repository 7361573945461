import Vue from "vue";
import App from "./App.vue";
import store from "./vuex/setup";
import router from "./router";

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: {
    id: "G-955NLXRSY7",
  },
});

import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
