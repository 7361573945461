import { auth, firebase } from "@/db/Firebase";

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  isSignInWithEmailLink,
  signInWithEmailLink,
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
} from "firebase/auth";

import connectWidgets from "@/vuex/widgets-watcher.js";
import connectUserdata from "@/vuex/variable-watcher.js";

import store from "@/vuex/setup";

async function externalLogin(provider, providerAuth) {
  return await signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = providerAuth.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;

      store.state.user = user;

      return user;
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = providerAuth.credentialFromError(error);

      return null;
      // ...
    });
}

export default {
  async loginFB() {
    let provider = new FacebookAuthProvider();
    return externalLogin(provider, FacebookAuthProvider);
  },

  async loginGoogle() {
    let provider = new GoogleAuthProvider();
    return externalLogin(provider, GoogleAuthProvider);
  },

  async login(email, password) {
    return await auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        store.state.user = userCredential.user;
        return userCredential.user;
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async logout() {
    await auth
      .signOut()
      .then(async () => {
        await connectWidgets.runWatchers(null);
        await connectUserdata.runWatchers(null);
        store.state.user = null;
        window.open("/" + store.state.client_data.url, "_self")
        return null;
      })
      .catch(() => {
        throw "Not succesfully logout!";
      });
  },


  async logoutNoRedirect() {
    await auth
      .signOut()
      .then(async () => {
        await connectWidgets.runWatchers(null);
        await connectUserdata.runWatchers(null);
        store.state.user = null;
        return null;
      })
      .catch(() => {
        throw "Not succesfully logout!";
      });
  },

  async createUser(email, password) {
    return await auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        store.state.user = userCredential.user;
        return userCredential.user;
      })
      .catch((err) => {
        throw err.message;
      });
  },

  async deleteUser(user) {
    await user.delete().catch((error) => {
      throw ("Error: user is not deleted! ", error);
    });
  },

  async mailAvailable(email) {
    return await auth
      .fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        if (signInMethods.length) {
          //user does already exist
          return false;
        } else {
          //user doesn't exist
          return true;
        }
      })
      .catch((error) => {
        throw error.message;
      });
  },

  async userState() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  },

  async checkState(router) {
    var unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        router.push("/");
      }
    });

    await unsubscribe();
  },

  async checkState2(router, path) {
    var unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        router.push(path)
      }
    });

    await unsubscribe();
  },

  async resetEmail(email) {
    // const actionCodeSettings = {
    //   // URL you want to redirect back to. The domain (www.example.com) for this
    //   // URL must be in the authorized domains list in the Firebase Console.
    //   url: window.location.href,
    //   // This must be true.
    //   handleCodeInApp: true,
    // };
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: "https://themozo.app/afterPasswordReset",
      handleCodeInApp: true,
    };
    return await firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(function () {
        return true
      })
      .catch(function (error) {
       return false
      });

     

    // return auth
    //   .sendPasswordResetEmail(email)
    //   .then(() => {
    //     return true;
    //   })
    //   .catch(() => {
    //     return false;
    //   });
  },

  async registerWithEmailLink(email) {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.href,
      // This must be true.
      handleCodeInApp: true,
    };
    try {
      await auth.sendSignInLinkToEmail(email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      // this.emailSent = true;
    } catch (err) {
      this.errorMessage = err.message;
    }
  },

  async resetPassword(code, password) {
    return auth
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  async getCredential(email, password) {
    return await auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );

        return credential;
      })
      .catch((err) => {
        const message1 =
          "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
        if (err.message == message1) {
          throw "Access to this account has been temporarily disabled due to many failed login attempts. You can try again later.";
        }

        throw err.message;
      });
  },

  async reauthenticate(user, password) {
    var credential = await this.getCredential(user.email, password).catch(
      (err) => {
        throw err;
      }
    );

    var isAuthenticated = await user
      .reauthenticateWithCredential(credential)
      .catch((err) => {
        throw "Sorry " + err.message;
      });

    if (isAuthenticated) {
      return true;
    }
  },

  async changePassword(user, oldPassword, newPassword) {
    var credential = await this.getCredential(user.email, oldPassword).catch(
      (err) => {
        throw err.message;
      }
    );

    if (credential !== null) {
      var isUpdated = await user
        .updatePassword(newPassword)
        .then(() => {
          return true;
        })
        .catch((err) => {
          throw err.message;
        });

      if (isUpdated) {
        return true;
      } else {
        throw "Something went wrong!";
      }
    } else {
      return false;
    }
  },

  async updateEmail(user, newEmail, password) {
    await this.reauthenticate(user, password).catch((err) => {
      throw err;
    });
    user.updateEmail(newEmail);
  },
};
