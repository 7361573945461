<template>
  <div>
    <template v-if="menu">
      <AccountMenu />
    </template>
    <div class="background mozo" :style="setBackground(color[0], color[1])">
      <Header :header_title="'Profile QR code'" :showLock="false" />
      <div class="page-body _80vh center">
        <div class="center qr">
          <div class="max-height">
            <VueQRCodeComponent :text="url" bg-color="rgb(255,255,255,0)" color="#fff">
            </VueQRCodeComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/TableManagement/General/Header2.vue"
import VueQRCodeComponent from "vue-qr-generator"
import AccountMenu from "@/views/Menu/LoggedIn.vue"

import methods from "@/general/HelperMethods/General"

export default {
  components: {
    Header,
    VueQRCodeComponent,
    AccountMenu,
  },

  data: () => {
    return {
      url: "",
    }
  },

  computed: {
    store: function () {
      return this.$store.state
    },
    menu: function () {
      return this.store.environment.menu
    },
    showQR: {
      get() {
        return this.store.environment.showQR
      },
      set(value) {
        this.store.environment.showQR = value
      },
    },
    client_data: function () {
      return this.store.client_data
    },
    color: function () {
      return this.client_data.color
    },
  },

  created() {
    this.url = "https://themozo.app/" + this.client_data.url
  },

  methods: {
    goBack() {
      this.showQR = false
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },
}
</script>

<style scoped>
.page-body.center {
  min-height: 0px;
}

@media screen and (min-width: 479px) and (max-width: 767px) {
  .header-column-tm {
    padding: 0% 5%;
  }
}
</style>
