<template>
  <div class="header-div-nd" :style="'margin-bottom:' + marginbottom">
    <div class="div-block-84" :style="styleBG">
 
      <div class="header-column-new-2">
        <div class="logo-header">
          <div class="logo-div">
            <img
              v-if="!hideLogo"
              :src="brand_info.logo"
              loading="lazy"
              alt="mozo hospitality"
              class="logo-img"
            />
          </div>
        </div>
        <div class="center">
          <div class="pf">
            <ProfilePicture @error="setError" />
          </div>

          <div
            v-if="imageError != ''"
            style="
              position: absolute;
              background-color: white;
              color: red;
              padding: 5%;
              border-radius: 28px;
            "
          >
            {{ imageError }}
          </div>
        </div>
        <div class="header-div" >
          <div v-if="loggedIn && isEdit" class="settings-div right">
            <a @click="setIcon()" class="link-block w-inline-block">
              <img :src="icon" loading="lazy" alt="" class="settings-image" />
            </a>
          </div>
          <div class="settings-div">
            <a @click="showMenu()" class="link-block w-inline-block">
              <img
                src="images/hamburger-menu.svg"
                loading="lazy"
                alt=""
                class="settings-image"
              />
            </a>
          </div>
        </div>
      </div>
      <Dropdown :foldout="foldout" />
    </div>
    <template v-if="!config">
      <div class="grid">
        <div></div>
        <div class="center">
          <div @click="foldoutMenu" class="menu-toggle-2 clickable">
            <div
              class="menu-icon-2 fold-out"
              :style="'transform: rotate(' + rotation + 'deg);'"
            ></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProfilePicture from "@/general/Elements/2.0/Config/ChangeProfileImage.vue"
import Dropdown from "@/general/Elements/2.0/Menu/Dropdown.vue"
import json from "@/general/GlobalValues.json"

export default {
  components: {
    ProfilePicture,
    Dropdown,
  },
  props: [],

  data: () => {
    return {
      rotation: "180",
      foldout: "",
      LOCK: "images/padlock.svg",
      UNLOCK: "images/unlock-padlock.svg",
      brand_info: json["brand"],
      marginbottom: "",
      imageError: "",

      styleBG:""
    }
  },

  created() {
    this.styleBG = 'backdrop-filter: brightness(55%) blur(25px);'
    if (!this.config) {
      this.icon = this.LOCK
    } else {
      this.icon = this.UNLOCK
      this.marginbottom = "5%"
    }

 
  },

  methods: {
    foldoutMenu() {
      this.styleBG = "background-color:rgb(0,0,0,0.4)"
      
      if (this.rotation == "0") {
        this.foldout = ""
        this.rotation = "180"
        
      } else {
        this.foldout = "foldout"
        this.rotation = "0"
      }

      setTimeout(()=> {
        this.styleBG = 'backdrop-filter: brightness(55%) blur(25px);'
        },600)
      
    },

    setIcon() {
      if (this.config) {
        this.icon = this.LOCK
        this.config = false
        this.marginbottom = "0%"
      } else {
        this.icon = this.UNLOCK
        this.config = true
        this.marginbottom = "5%"
      }
    },

    showMenu() {
      this.menu = true
    },

    pickHex(color1, color2, weight) {
      var w1 = weight
      var w2 = 1 - w1
      var rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2),
      ]
      return rgb
    },

    getBackgroundShadow() {
      return "box-shadow: 3px 3px 20px -4px" + this.color[0] + ";"
    },

    setError(error) {
      this.imageError = error
    },
  },

  computed: {
    store: function () {
      return this.$store.state
    },

    isEdit: function () {
      return this.store.environment.isEdit
    },

    profile_picture: {
      get() {
        return this.store.client_data.image
      },
      set(value) {
        this.store.client_data.image = value
      },
    },

    color: function () {
      return this.store.client_data.color
    },

    config: {
      get() {
        return this.store.environment.config
      },
      set(value) {
        this.store.environment.config = value
      },
    },

    loggedIn: function () {
      return this.store.environment.loggedIn
    },

    menu: {
      get() {
        return this.store.environment.menu
      },
      set(value) {
        this.store.environment.menu = value
      },
    },

    uid: function () {
      return this.user.uid
    },
    hideLogo: function () {
      return this.store.client_data.hideMozoLogo
    },
  },
}
</script>

<style scoped>

.menu-toggle-2 {
  padding-top: 1%;
}

.clickable {
  cursor: pointer;
}

.fold-out-menu {
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  max-height: 0;
  -webkit-transition: max-height, 0.8s ease-in-out;
  transition: max-height, 0.8s ease-in-out;
  overflow: hidden;
  flex-direction: column;
}

.foldout {
  max-height: 300px;
}

.center {
  width: 100%;
  height: 100%;
}

.pf {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  margin: 5px 0 5px 0;
}

.header-div-nd {
  height: auto;

} 

@media screen and (max-width: 991px) {
  .header-div-nd {
    height: auto;

  }
}

.div-block-84 {
    backdrop-filter: blur(25px);
    display:static;
}

@media screen and (max-width: 767px) {
  .header-div-nd {
    height: auto;
  }
}

/* @media screen and (max-width: 479px) {
  .menu-toggle-2 {
    padding: 7px;
  }
} */

/* @media screen and (max-width: 479px) {
.header-div-nd {
  height:auto;
}
} */
</style>
