<template>
  <div
    class="background mozo"
    :style="setBackground(client_data.color[0], client_data.color[1])"
  >
    <Header />
    <div style="width: 100%; min-height: 65vh">
      <div style="display: flex; justify-content: center; align-items: center">
        <Heading1 :text="client_data.bio2" @isClicked="goCommunity" />
      </div>
      
      <template v-if="!hideLogo">
        <ShortWidgets />
      </template>
      <ImgWidgets />
      <Widgets />
      <template v-if="hideLogo">
        <ShortWidgets />
      </template>
      <Banner />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/general/Components/2.0/Header.vue"
import Heading1 from "@/general/Elements/2.0/OtherElements/HeadingFillin.vue"
import ShortWidgets from "@/general/Components/2.0/ShortWidgets.vue"
import ImgWidgets from "@/general/Components/2.0/ImgWidgets.vue"
import Widgets from "@/general/Components/2.0/Widgets.vue"
import Banner from "@/general/Components/2.0/Banner.vue"
import Footer from "@/general/Components/2.0/Footer.vue"

import methods from "@/general/HelperMethods/General"

export default {
  name: "App",
  components: {
    Header,
    ShortWidgets,
    ImgWidgets,
    Widgets,
    Banner,
    Footer,
    Heading1,
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    client_data: {
      get() {
        return this.store.client_data
      },
      set(value) {
        this.store.client_data = value
      },
    },
    hideLogo: function () {
      return this.store.client_data.hideMozoLogo
    },
  },
  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
    goCommunity() {

      window.open(
        "https://community.themozo.app/" + this.store.client_data.uid,
        "_self"
      );
    },
  },
}
</script>
