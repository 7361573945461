<template>
  <div class="background mozo _100vh" :style="setBackground()">
    <Header />
    <div class="page-body-center">
      <div class="form-div width-cap">
        <h1 class="heading1 bold">Welcome again</h1>
        <p class="paragraph">Login to your account</p>
        <form class="form-block">
          <InputField
            :placeholder="'Login'"
            @pressEnter="login"
            @returnfield="setValue('username', $event)"
          />
          <InputField
            :type="'password'"
            placeholder="Password"
            @pressEnter="login"
            @returnfield="setValue('password', $event)"
          />
          <div class="div-block">
            <div class="columns w-row">
              <div class="w-col w-col-6"></div>
              <div class="w-col w-col-6">
                <div class="rightalign-div">
                  <a href="/reset-password" class="paragraph white">Forgot password?</a>
                </div>
              </div>
            </div>
          </div>
        </form>

        <Button
          :color="client_data.color[2].icons[0]"
          :title="'Login'"
          @isClicked="login"
        />

        <template v-if="error !== ''">
          <div>
            <div id="error-message" class="error-message">{{ error }}</div>
          </div>
        </template>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
import { auth } from "@/db/Firebase"

// DB
import db_auth from "@/db/2.0/auth.js"

// in elements 2.0
import LoginFooter from "@/general/Elements/2.0/OtherElements/Login/LoginFooter.vue"
import Header from "@/general/Elements/2.0/Headers/Header1.vue"
import InputField from "@/general/Components/2.0/PrivateProfile/InputField.vue"

import methods from "@/general/HelperMethods/General"

import Button from "@/general/Components/2.0/PrivateProfile/GreenButton.vue"

export default {
  components: {
    LoginFooter,
    Header,
    InputField,
    Button,
  },
  data() {
    return {
      error: "",
      username: null,
      password: null,
    }
  },
  created() {
    db_auth.checkState(this.$router)
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    client_data() {
      return this.store.client_data
    },
  },

  methods: {
    setValue(key, value) {
      this[key] = value
    },
    login() {
      auth
        .signInWithEmailAndPassword(this.username, this.password)
        .then(() => {
          this.$router.push("/")
        })
        .catch((err) => {
          let errorCode = err.code

          if (errorCode === "auth/invalid-email") {
            this.error = "Sorry you have used the wrong username or password"
          } else if (errorCode === "auth/user-disabled") {
            this.error = "User account has been disabled"
          } else if (errorCode == "auth/too-many-requests") {
            this.error =
              "Sorry you have too many login attempts try it again over 10 minutes."
          } else if (errorCode === "auth/user-not-found") {
            this.error = "Sorry you have used the wrong username or password"
          } else if (errorCode === "auth/wrong-password") {
            this.error = "Sorry you have used the wrong username or password"
          } else {
            this.error = "Sorry you have used the wrong username or password"
          }
        })
    },
    setBackground() {
      if (this.client_data && this.client_data.color[0] && this.client_data.color[1]) {
        return methods.setBackground(this.client_data.color[0], this.client_data.color[1])
      } else {
        return ""
      }
    },
  },
}
</script>

<style scoped>
.page-body-center {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 70vh;
  width: 100%;
}

.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 5%;
}
</style>
